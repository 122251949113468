<template>
    <v-layout column>
        <h1>{{ pageTitle }}</h1>
    </v-layout>
</template>

<script>
export default {
    data() {
        return {
            pageTitle: 'Setup Overview',
        }
    },
}
</script>